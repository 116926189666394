import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { FC, Fragment } from 'react';

export type HeaderLink = {
  src: string;
  text: string;
};

const headerLinks: HeaderLink[] = [
  {
    src: '/for-resellers/',
    text: 'For Resellers',
  },
  {
    src: '/',
    text: 'For Food Service Owners',
  },
  {
    src: '/team/',
    text: 'Team',
  },
  {
    src: '/blog/',
    text: 'Blog',
  },
];

type HeaderProps = {
  overrideHeaders?: HeaderLink[];
};

const Header: FC<HeaderProps> = ({ overrideHeaders }) => {
  const navLinks = overrideHeaders ?? headerLinks;

  const {
    site: { siteMetadata },
    logo,
  } = useStaticQuery(graphql`
    query Header {
      site {
        siteMetadata {
          appLink
        }
      }
      logo: file(relativePath: { eq: "logo-square.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 40
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  return (
    <Popover className={clsx('relative font-semibold', 'pt-28 lg:pt-10')}>
      <div className="fixed top-0 w-full z-20 bg-primary text-white flex flex-col lg:flex-row text-center justify-center gap-2 lg:gap-4 p-4 lg:py-2 items-center">
        <div>
          OptiOffer starts raising $4.3M to fund <br className="lg:hidden" />
          the future of Supplier Reseller Platforms
        </div>
        <Link
          to="/blog/seed-funding-the-future-of-b2b/"
          className="rounded-md py-2 px-6 inline-flex items-center justify-center focus:outline-none ring-2 ring-inset ring-white"
        >
          Learn more about our Seed Round
        </Link>
      </div>

      <header className="max-w-7xl mx-auto px-4 sm:px-6">
        <div
          className={clsx(
            'flex justify-between items-center py-6 md:justify-start md:space-x-10'
          )}
        >
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/for-resellers/">
              <span className="sr-only">OptiOffer</span>
              <GatsbyImage
                image={getImage(logo)}
                alt="optioffer logo"
                objectFit="contain"
                className="h-8 w-auto sm:h-10"
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            {navLinks.map((link) => (
              <Link
                key={`${link.src}-${link.text}`}
                to={link.src}
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                {link.text}
              </Link>
            ))}
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <a
              href={siteMetadata.appLink}
              className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Sign in
            </a>
            <Link
              to="/for-resellers/#join-beta"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary-200"
            >
              Get access
            </Link>
          </div>
        </div>
      </header>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          {({ close }) => (
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <GatsbyImage
                      image={getImage(logo)}
                      alt="optioffer logo"
                      objectFit="contain"
                      className="h-8 w-auto"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-transparent rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                  {navLinks.map((link) => (
                    <Link
                      key={`${link.src}-${link.text}`}
                      to={link.src}
                      onClick={() => close()}
                      className="text-base text-center font-medium text-gray-900 hover:text-gray-700"
                    >
                      {link.text}
                    </Link>
                  ))}
                </div>
                <div>
                  <Link
                    to="/for-resellers/#join-beta"
                    onClick={() => close()}
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary-200"
                  >
                    Get access
                  </Link>
                  <p className="mt-6 text-center text-base font-medium text-gray-500">
                    Existing customer?{' '}
                    <a
                      href={siteMetadata.appLink}
                      className="text-primary hover:text-primary"
                    >
                      Sign in
                    </a>
                  </p>
                </div>
              </div>
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Header;
