import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import {
  FC,
  InputHTMLAttributes,
  ReactNode,
  TextareaHTMLAttributes,
} from 'react';

import './TextInput.css';

export type TextInputProps = {
  className?: string;
  innerClassName?: string;

  formik: ReturnType<typeof useFormik>;
  name: string;
  label?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  showError?: boolean;
  autoFocus?: boolean;
  inputProps?: TextareaHTMLAttributes<any> | InputHTMLAttributes<any>;

  startPiece?: ReactNode;
  endPiece?: ReactNode;
};

const TextInput: FC<TextInputProps> = ({
  className,
  innerClassName,

  formik,
  name,
  label,
  type = 'text',
  placeholder,
  disabled,
  showError = true,
  autoFocus,
  inputProps = {},

  startPiece,
  endPiece,
}) => {
  const Input = type === 'textarea' ? 'textarea' : 'input';
  return (
    <div
      className={clsx(className, 'TextInput-wrapper', disabled && 'opacity-50')}
    >
      {label && (
        <label htmlFor={name} className="block mb-0 flex">
          {label}
        </label>
      )}
      <div
        className={clsx(
          innerClassName,
          `TextInput-input flex w-full p-4 py-1 items-center           
          rounded border border-black border-opacity-30
          shadow-sm bg-white`,
          label && 'mt-1',
          disabled ? '' : 'hover:bg-background-avg'
        )}
      >
        {startPiece && (
          <div className="opacity-60 mr-4 whitespace-nowrap">{startPiece}</div>
        )}
        <Input
          id={name}
          type={type}
          {...formik.getFieldProps(name)}
          className="block w-full"
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          {...inputProps}
        />
        {endPiece && (
          <div className="opacity-60 mx-4 whitespace-nowrap">{endPiece}</div>
        )}
      </div>

      {showError && formik.touched[name] && formik.errors[name] ? (
        <div className="text-error text-sm">{formik.errors[name]}</div>
      ) : null}
    </div>
  );
};

export default TextInput;

export type PasswordEndPieceProps = {
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PasswordEndPiece: FC<PasswordEndPieceProps> = ({
  setShowPassword,
}) => (
  <svg
    className="h-6 text-primary cursor-pointer"
    onMouseDown={() => setShowPassword(true)}
    onMouseUp={() => setShowPassword(false)}
    onMouseLeave={() => setShowPassword(false)}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
    <path
      fillRule="evenodd"
      d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
      clipRule="evenodd"
    />
  </svg>
);
