import TextInput from '@/components/form/TextInput';
import { ShoppingBasketContext } from '@/lib/shoppingBasket';
import { Popover, Transition } from '@headlessui/react';
import {
  ArrowLeftIcon,
  ChevronRightIcon,
  ClipboardListIcon,
  MenuIcon,
  ShoppingCartIcon,
  BriefcaseIcon,
  UserIcon,
  XIcon,
} from '@heroicons/react/outline';
import { SearchIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { FC, Fragment, ReactNode, useContext, useMemo, useState } from 'react';

type NewHeaderProps = {
  overrideSearchForm?: ReactNode;
  hideCategoriesMenu?: boolean;
};

const NewHeader: FC<NewHeaderProps> = ({
  overrideSearchForm,
  hideCategoriesMenu = false,
}) => {
  const {
    site: { siteMetadata },
    productCategories: { edges: productCategories },
    supplierCategories: { group: supplierCategories },
    professionalCategories: { group: professionalCategories },
  } = useStaticQuery(graphql`
    fragment CategoryOnHeader on Category {
      name
      path
    }

    query {
      site {
        siteMetadata {
          appLink
        }
      }

      productCategories: allCategory(
        filter: { qualifiedName: { regex: "/^[^_]*$/" } }
      ) {
        edges {
          node {
            ...CategoryOnHeader
            subCategories {
              ...CategoryOnHeader
            }
          }
        }
      }
      supplierCategories: allSupplier {
        group(field: category) {
          fieldValue
        }
      }
      professionalCategories: allProfessional {
        group(field: category) {
          fieldValue
        }
      }
    }
  `);
  const [currentMenuItem, setCurrentMenuItem] = useState(null);

  const mobileMenu = useMemo(
    () => [
      {
        label: 'Cauta Produse',
        path: null,
        children: productCategories
          .map(({ node }) => node)
          .map((category) => ({
            label: category.name,
            path: category.path,
            children: category.subCategories
              ? category.subCategories.map((subCat) => ({
                  label: subCat.name,
                  path: subCat.path,
                  children: [],
                }))
              : [],
          })),
      },
      {
        label: 'Gasete Furnizori',
        path: '/ro/cauta-furnizori/',
        children: supplierCategories.map(({ fieldValue }) => ({
          label: fieldValue,
          path: `/ro/cauta-furnizori/?category=${encodeURIComponent(
            fieldValue
          )}`,
          children: [],
        })),
      },
      {
        label: 'Alege Profesionisti',
        path: '/ro/cauta-profesionisti/',
        children: professionalCategories.map(({ fieldValue }) => ({
          label: fieldValue,
          path: `/ro/cauta-profesionisti/?category=${encodeURIComponent(
            fieldValue
          )}`,
          children: [],
        })),
      },
    ],
    [productCategories, supplierCategories, professionalCategories]
  );

  const searchFormik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: ({ search }) => {
      return navigate(`/ro/cauta-produse/?q=${search}`);
    },
  });
  const { items } = useContext(ShoppingBasketContext);
  const [categoryPopoverOpen, setCategoryPopoverOpen] = useState(false);

  function renderActualHeader() {
    return (
      <header>
        <div className="px-4 bg-primary-300">
          <div className=" lg:container mx-auto items-center pt-2 pb-3 lg:pb-2 lg:px-4 grid grid-cols-4 gap-y-3">
            <div className="flex justify-start flex-1">
              {!hideCategoriesMenu && (
                <Popover.Button
                  className="mr-2 inline-flex items-center justify-center text-white"
                  onClick={() => setCategoryPopoverOpen((it) => !it)}
                >
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              )}

              <Link to="/ro/">
                <div className={clsx('text-lg font-bold text-white')}>
                  OPTIOFFER
                </div>
              </Link>
            </div>

            {overrideSearchForm ? (
              <div className="row-start-2 col-start-1 col-span-5 lg:row-start-1 lg:col-start-2 lg:col-span-2">
                {overrideSearchForm}
              </div>
            ) : (
              <form
                onSubmit={searchFormik.handleSubmit}
                className={clsx(
                  'row-start-2 col-start-1 col-span-5 lg:row-start-1 lg:col-start-2 lg:col-span-2'
                )}
              >
                <TextInput
                  name="search"
                  placeholder="Cauta produse transpatent"
                  innerClassName="py-1 pr-0"
                  endPiece={
                    <SearchIcon
                      className="h-5 w-5 -mr-2 cursor-pointer"
                      onClick={() => searchFormik.submitForm()}
                    />
                  }
                  formik={searchFormik}
                />
              </form>
            )}

            <Link
              to="/ro/oferta-mea/"
              className={clsx(
                'flex items-center justify-self-end col-start-4 text-white'
              )}
            >
              <div className="relative">
                <ClipboardListIcon className="h-6 w-6" />
                <div
                  style={{ fontSize: '13px' }}
                  className={clsx(
                    'min-w-4 px-1 h-4 flex items-center justify-center rounded-full bg-yellow text-black absolute right-0 top-0 transform translate-x-1/3 -translate-y-1/3',
                    !items.length && 'hidden'
                  )}
                >
                  {items.length}
                </div>
              </div>
              <div className="hidden lg:block ml-2 font-medium">Oferta mea</div>
            </Link>

            <a
              id="joinAsPRO"
              href={`${siteMetadata.appLink}/direct-signup`}
              target="_blank"
              rel="noreferrer"
              className="col-start-5 ml-4 px-3 lg:px-8 py-2 border border-transparent rounded shadow-sm font-semibold bg-yellow hover:bg-yellow"
            >
              Join as PRO
            </a>
          </div>
        </div>
        <div className="hidden lg:flex py-3 px-4 gap-12 justify-center items-center">
          <Link to="/ro/cauta-produse/" className="flex">
            <ShoppingCartIcon className="h-5 w-5 mr-2" />
            Cauta Produse
          </Link>
          <Link to="/ro/cauta-furnizori/" className="flex">
            <BriefcaseIcon className="h-5 w-5 mr-2" />
            Gaseste Furnizori
          </Link>
          <Link to="/ro/cauta-profesionisti/" className="flex">
            <UserIcon className="h-5 w-5 mr-2" />
            Alege Profesionisti
          </Link>
        </div>
      </header>
    );
  }

  return (
    <Popover className={clsx('relative font-semibold')}>
      {renderActualHeader()}

      <Transition
        as={Fragment}
        show={categoryPopoverOpen}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          static
          className="absolute z-30 top-0 inset-x-0 lg:right-auto p-2 transition transform origin-top-right"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="px-4 py-2">
              <div className="flex items-center">
                {currentMenuItem && (
                  <button
                    type="button"
                    onClick={() => setCurrentMenuItem(null)}
                    className="inline-flex items-center justify-center text-primary"
                  >
                    <ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                )}
                <div className="flex-1 text-primary text-base text-center">
                  {currentMenuItem ? currentMenuItem.label : 'OptiOffer'}
                </div>
                {!currentMenuItem && (
                  <button
                    type="button"
                    className="inline-flex items-center justify-center text-primary"
                    onClick={() => {
                      setCurrentMenuItem(null);
                      setCategoryPopoverOpen(false);
                    }}
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                )}
              </div>
            </div>
            <div className="py-6 px-4 space-y-6">
              <div className="grid grid-cols-1 gap-x-8">
                {(currentMenuItem?.children || mobileMenu)
                  .sort((s1, s2) => s2.children.length - s1.children.length)
                  .map((menuItem, idx, self) => (
                    <Fragment key={menuItem.path}>
                      <button
                        className="text-left font-semibold text-gray-900 flex justify-between py-2"
                        onClick={() => {
                          if (menuItem.children?.length) {
                            setCurrentMenuItem(menuItem);
                            return;
                          }
                          navigate(menuItem.path);
                          setCurrentMenuItem(null);
                          setCategoryPopoverOpen(false);
                        }}
                        type="button"
                      >
                        <span>{menuItem.label}</span>
                        {!!menuItem.children?.length && (
                          <ChevronRightIcon className="text-gray-500 h-4 w-4 ml-4" />
                        )}
                      </button>
                      {idx < self.length - 1 && (
                        <hr className="border-bg border-gray-250 -mx-4" />
                      )}
                    </Fragment>
                  ))}
              </div>

              <a
                id="joinAsPRO"
                href={`${siteMetadata.appLink}/direct-signup`}
                target="_blank"
                rel="noreferrer"
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary-200"
              >
                Join as PRO
              </a>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default NewHeader;
