import { FacebookIcon, InstagramIcon, LinkedinIcon } from '@/images/footer';
import clsx from 'clsx';
import { Link } from 'gatsby';
import * as React from 'react';
import { FC } from 'react';

type FooterProps = {
  minimal?: boolean;
};

const Footer: FC<FooterProps> = ({ minimal }) => {
  return (
    <footer
      className={clsx('relative z-10 bg-primary p-10', !minimal && 'rounded-t')}
    >
      {!minimal && (
        <div className="flex flex-col-reverse lg:flex-row uppercase">
          <div className="flex-1 flex flex-col space-y-1 mb-4 font-light text-lightPeach opacity-70">
            <Link to="/ro/">For Food Service Owners</Link>
            <Link to="/for-resellers/">For Resellers</Link>
            <Link to="/team/">Team</Link>
            <Link to="/blog/">Blog</Link>
          </div>
          <div className="flex-1 text-white mb-24">
            <div className="text-lg mb-6">Follow Us</div>
            <div className="flex space-x-5 text-lightPeach opacity-70">
              <a
                href="https://www.linkedin.com/company/optioffer/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinIcon />
              </a>
              <a
                href="https://www.instagram.com/optioffer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.facebook.com/OptiOffer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col-reverse lg:flex-row uppercase font-light text-xs text-lightPeach opacity-70">
        <div className="flex-1">© 2021 OptiOffer, All rights reserved.</div>
        <div className="flex-1 flex flex-col lg:flex-row space-y-1 mb-4 lg:space-y-0 lg:mb-0 lg:space-x-5">
          <Link to="/terms-and-conditions/">Terms & Conditions</Link>
          <Link to="/return-and-refund-policy/">Return & Refund Policy</Link>
          <Link to="/privacy-policy/">Privacy Policy</Link>
          <Link to="/cookie-policy/">Cookie Policy</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
