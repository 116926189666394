import Footer from '@/components/Footer';
import Header from '@/components/Header';
import NewHeader from '@/components/NewHeader';
import * as React from 'react';
import { FC, ReactNode } from 'react';

type LayoutProps = {
  className?: string;
  mainClassName?: string;
  hideHeader?: boolean;
  fsoHeader?: boolean;
  fsoHeaderOverrideSearchForm?: ReactNode;
  fsoHeaderHideCategoriesMenu?: boolean;
  minimalFooter?: boolean;
};

const Layout: FC<LayoutProps> = ({
  children,
  className,
  mainClassName,
  hideHeader,
  fsoHeader,
  fsoHeaderOverrideSearchForm,
  fsoHeaderHideCategoriesMenu,
  minimalFooter,
}) => {
  return (
    <>
      <div className={className}>
        {!hideHeader &&
          (fsoHeader ? (
            <NewHeader
              overrideSearchForm={fsoHeaderOverrideSearchForm}
              hideCategoriesMenu={fsoHeaderHideCategoriesMenu}
            />
          ) : (
            <Header />
          ))}
        <main className={mainClassName}>{children}</main>
        <Footer minimal={minimalFooter} />
      </div>
    </>
  );
};

export default Layout;
